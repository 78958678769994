<template>
  <product-template>
    <template v-slot:productInfo>
      <!-- Caracteristicas -->
      <caracteristicas :datos="producto.caracteristicas" />
      <!-- Galeria de características-->
      <img :src="getImgSource()" alt="galeria-caracteristicas" class="galeria-caracteristicas">
      <div class="contenedor-img-caracteristicas">
        <p class="nombre-imagenes-caracteristicas">Interruptor Sensible</p>
        <p class="nombre-imagenes-caracteristicas">Interfaces Múltiples</p>
        <p class="nombre-imagenes-caracteristicas">Diseño antigolpes</p>
        <p class="nombre-imagenes-caracteristicas">Pulsador Remoto</p>
        <p class="nombre-imagenes-caracteristicas">Colimado Regulable</p>
      </div>
      <!-- Especificaciones -->
      <especificaciones :datos="producto.especificaciones" />
      <!-- Galeria de imagenes para hacer Zoom -->
      <zoom-gallery
        :producto="producto"
        :title="producto.nombreCompleto"
        sectionName="radioDigital"
      ></zoom-gallery>
      <!-- Documentos -->
      <documentos :brochures="producto.brochures" />
    </template>
    <!-- Formulario de contacto -->
    <modal :header="false" v-if="showModal">
      <template #content>
        <contact-form
          @closeModal="showModal = false"
          :producto="producto.nombreCompleto"
        ></contact-form>
      </template>
    </modal>
  </product-template>
</template>
<script>
import { productMixin } from "../../../assets/js/productMixin";
import Caracteristicas from "../Caracteristicas.vue";
import Documentos from "../Documentos.vue";
import Especificaciones from "../Especificaciones.vue";
import ProductTemplate from "../productTemplate.vue";
import BannerContacto from "../BannerContacto.vue";
import ContactForm from "../../Forms/ContactForm";
import ZoomGalleryVue from "../../Common/ZoomGallery.vue";

export default {
  props: ["slug"],
  mixins: [productMixin],
  components: {
    "product-template": ProductTemplate,
    caracteristicas: Caracteristicas,
    documentos: Documentos,
    especificaciones: Especificaciones,
    "banner-contacto": BannerContacto,
    "contact-form": ContactForm,
    "zoom-gallery": ZoomGalleryVue,
  },
  data() {
    return {
      producto: "",
      showModal: false,
    };
  },
  beforeMount() {
    this.producto = this.getProductEquipment(this.slug);
  },
  methods: {
    getImgSource: function() {
      return require(`@/assets/images/productos/humanos/radioDigital/caracteristicas-moviles-digi-2.png`)
    }
  }
};
</script>
<style scoped>
.nombre-imagenes-caracteristicas{
  font-weight: bold;
  font-size: 80%;
}
.contenedor-img-caracteristicas{
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>